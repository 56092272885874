<template>
  <div class="auth__block">
    <div class="auth__block-row">
      <div class="auth__block-col auth__block-col--left" v-if="getRouteNameContent">
        <h2>{{ getRouteNameContentTitle }}</h2>
        <div ref="content" :class="{'auth__block-content': true, active: isShowContent}" v-html="getRouteNameContent"></div>
        <Button v-if="!isShowContent" class="auth__block-content-btn" @click.native="showContent">{{ $t('auth.btn.contentMore') }}</Button>
      </div>
      <div :class="{'auth__block-col auth__block-col--right':true, 'col-6': !getRouteNameContent}">
        <h1 class="title-h2 auth__title">
          {{ getRouteNameFormTitle }}
        </h1>
        <div class="auth__subtitle">
          <div v-if="type === 'hotels'" v-html="$t('main.sign_in_to_tour_operator_text2')"></div>
          <div v-html="getRouteNameFormText"></div>
        </div>
        <template v-if="type === 'certification-agency'">
          <AuthPasswordSimple />
        </template>
        <template v-else-if="type === 'hotels' || type === 'tour-operator'">
          <EuKey />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import EuKey from '@/elements/euKey/EuKey.vue'
import AuthPasswordSimple from '@/components/auth/login/AuthPasswordSimple.vue'

export default {
  components: {
    EuKey,
    AuthPasswordSimple,
  },
  data() {
    return {
      isShowContent: false,
    }
  },

  created() {
    this.$store.commit('SET_CURRENT_FORM_TYPE', 'auth')
  },

  computed: {
    // getRouteNameSutbtitleText() {
    //   return this.type === 'tour-operator'
    //     ? 'main.sign_in_to_tour_operator_text1'
    //     : 'main.sign_in_to_tour_operator_text2'
    // },

    type() {
      return this.$route.name.replace('auth-key-', '')
    },

    getRouteNameContentTitle() {
      return this.$t(`auth.${this.type}.content.title`)
    },

    getRouteNameContent() {
      let localizationKey = ''

      switch (this.type) {
        case 'tour-operator':
            localizationKey = 'tour-operator-license-procedure'
          break;

        case 'certification-agency':
            localizationKey = 'hotel-categorization-procedure'
          break;
      }

      if(!localizationKey) return '';

      const localizationObject =  this.$i18n.messages[this.$i18n.locale].faq[localizationKey].description

      return this.getContentHtml(localizationObject)
    },

    getRouteNameFormTitle() {
      return this.$t(`auth.${this.type}.form.title`)
    },

    getRouteNameFormText() {
      return this.$t(`auth.${this.type}.form.text`)
    },
  },

  methods: {
    getContentHtml(items) {
      let html = ''

      Object.entries(items).forEach((row, i) => {
        if (typeof row[1] === 'string') {
          html += `<p>${row[1]}</p>`
        } else if (typeof row[1] === 'object') {
          let list = ''

          for (let k in row[1]) {
            if (k === 'title') {
              html += `<p>${row[1][k]}</p>`
            } else {
              list += `<li>${row[1][k]}</li>`
            }
          }

          html += `<ul>${list}</ul>`
        }
      })

      return html
    },

    showContent() {
      this.isShowContent = !this.isShowContent

      const el = this.$refs.content

      el.style.transition = 'all .3s ease 0s'
      el.style.height = `${el.offsetHeight}px`

      setTimeout(() => {
        el.style.height = `${el.scrollHeight}px`
        
        setTimeout(() => {
          el.style.transition = null
          el.style.height = 'auto'
        }, 350);
      });
    }
  },

}
</script>

<style lang="sass">
.auth__subtitle
  font-size: 16px
  line-height: 1.4
  margin-bottom: 5rem

.auth
  &__block
    &-row
      display: flex
      gap: 80px

      @include m
        flex-wrap: wrap
        flex-direction: column-reverse

    &-col
      &--left
        width: 60%

        @include m
          width: 100%

        h2
          font-size: 28px
          line-height: 32px
          margin-bottom: 24px

          @include m
            font-size: 24px

      &--right
        width: 40%

        &.col-6
          width: 50%

        @include m
          width: 100%

    &-content

      &.active
        &::after
          opacity: 0

      @include m
        height: 380px
        overflow: hidden
        position: relative

        &::after
          content: ""
          pointer-events: none
          position: absolute
          left: 0
          bottom: 0
          height: 140px
          width: 100%
          background: linear-gradient(180deg, rgba(255,255,255, 0) 12%, rgba(255, 255, 255, 1) 100%)
          opacity: 1
          transition: .3s ease 0s

      &-btn
        display: none

        @include m
          display: flex
          margin: 32px auto 0 auto
      
      p, li
        margin-bottom: 16px
        font-size: 18px
        line-height: 28px

        @include m
          font-size: 16px

      ul
        list-style: inherit
        margin-left: 42px

        @include m
          margin-left: 24px

      a
        word-break: break-all
        &:hover
          text-decoration: underline
</style>

